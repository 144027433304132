<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import {routerHelper} from "@/helpers/router-helper";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {paginationHelper} from "@/helpers/pagination-helper";
import allCountries from "@/helpers/all-countries";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

/**
 * Shipping DPD Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    Layout,
    PageHeader
  },

  data() {

    return {

      submitted: false,

      shopOrder: null,
      shopOrderProducts: null,
      productVariations: null,
      products: null,
      customerAddress: null,
      deliveryAddress: null,
      packageSenders: null,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: ""
      },

      form: {

        productIds: [],

        parcelData: {

          width: 0,
          height: 0,
          depth: 0,
          weight: 0,

          content: "",
          parcelCount: 1,
          packageSenderId: "",

          firstName: "",
          lastName: "",
          phoneNumber: "",
          street: "",
          postCode: "",
          city: "",
          countryCode: "PL",
          dispatchOrder: false

        }

      }

    };

  },

  validations: {
    form: {

      parcelData: {

        width: { required },
        height: { required },
        depth: { required },
        weight: { required },
        content: { required },
        parcelCount: { required },
        packageSenderId: { required },

        firstName: { required },
        lastName: { required },
        phoneNumber: { required },
        street: { required },
        postCode: { required },
        city: { required },
        countryCode: { required }
      }

    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.shipping.dpd.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "id", label: 'Zamówienie nr ' + this.shopOrder.idAsNumber },
        {key: 'quantity', label: 'Ilość (szt)'},
        {key: 'width', label: 'Szerokość (cm)'},
        {key: 'height', label: 'Wysokość (cm)'},
        {key: 'length', label: 'Długość (cm)'},
        {key: 'weight', label: 'Waga (kg)'}
      ]
    },

    getAllCountries() {
      return allCountries;
    },

    async loadShopOrder() {
      try {
        const params = routerHelper.params();
        const orderId = params.orderId;
        if (!orderId) {
          return;
        }

        const result = await axios.get(`/shop-order/fetch`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "shopOrderId": orderId,
          }
        });

        this.shopOrder = result.data
        await this.loadOrderProducts()
        await this.loadCustomerAddress()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadCustomerAddress() {
      try {
        const result = await axios.get(`/customer/address`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "customerAddressId": this.shopOrder.customerAddressId
          }
        });

        this.customerAddress = result.data
        await this.loadDeliveryAddress()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadOrderProducts() {
      try {
        const json = JSON.stringify({
          "ids": [this.shopOrder.id]
        })

        const result = await axios.post(`/shop-order/product/fetch/by/shop-order`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.shopOrderProducts = result.data
        this.table.rows = this.shopOrderProducts.length
        this.table.totalRows = this.shopOrderProducts.length
        await this.loadProductVariationsByIds()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProductVariationsByIds() {
      try {
        const productVariationIds = [];

        for (const product of this.shopOrderProducts) {
          productVariationIds.push(product.productVariationId);
        }

        const json = JSON.stringify({
          "ids": productVariationIds
        });

        const result = await axios.post(`/product/variation/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.productVariations = new Map(result.data.map((obj) => [obj.id, obj]));
        await this.loadProducts()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProducts() {
      try {
        const productIds = [...this.productVariations.values()].map(obj => obj.productId);

        const json = JSON.stringify({
          "ids": productIds
        });

        const result = await axios.post(`/product/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.products = new Map(result.data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getProductVariation(id) {
      if (!this.productVariations) {
        return null
      }

      return this.productVariations.get(id) || null
    },

    getProduct(id) {
      if (!this.products) {
        return null
      }

      return this.products.get(id) || null
    },

    selectAllProducts() {
      const ids = []
      for (const product of this.shopOrderProducts) {
        ids.push(product.id)
      }

      const sameIds = this.form.productIds.length === ids.length
          && this.form.productIds.every(item => ids.includes(item))
          && this.form.productIds.every(item => ids.includes(item));

      if (sameIds) {
        this.form.productIds = []
      } else {
        this.form.productIds = ids
      }
    },

    isSameProductIds() {
      const ids = []
      for (const product of this.shopOrderProducts) {
        ids.push(product.id)
      }

      return this.form.productIds.length === ids.length
          && this.form.productIds.every(item => ids.includes(item));
    },

    async loadPackageSenders() {
      try {
        const result = await axios.get(`/logistics/package-sender/list-all-active`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.packageSenders = result.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadDeliveryAddress() {
      try {
        const result = await axios.get(`/address/${this.customerAddress.deliveryId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.deliveryAddress = result.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    fillInputs() {
      const { firstName, lastName, phoneNumber, street, postCode, city, country } = this.deliveryAddress

      this.form.parcelData.content = "Zamówienie nr. " + this.shopOrder.idAsNumber
      this.form.parcelData.firstName = firstName
      this.form.parcelData.lastName = lastName
      this.form.parcelData.phoneNumber = phoneNumber
      this.form.parcelData.street = street;
      this.form.parcelData.postCode = postCode;
      this.form.parcelData.city = city;
      this.form.parcelData.countryCode = country;
    },

    send() {
      this.submitted = true

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.form.productIds.length === 0) {
        Swal.fire("Błąd", "Wybierz przynajmniej jeden produkt", "error");
        return
      }

      const json = JSON.stringify({
        "shopOrderId": this.shopOrder.id,
        "shopOrderProductIds": this.form.productIds,
        "parcelData": this.form.parcelData
      });

      axios.post(`/logistics/dpd/send-parcel`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        Swal.fire("Sukces!", "Zlecono przesyłkę!", "success").then(() => {
          window.location = "/dashboard/core/order/details?shopOrderId=" + this.shopOrder.id
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  },

  async created() {
    await this.loadShopOrder()
    await this.loadPackageSenders()
    await this.fillInputs()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('core.shipping.dpd.title')" :items="getItems()" />

    <div class="row">
      <div class="col-12">
        <template v-if="shopOrder">
          <div class="card">
            <div class="card-body">

              <div class="py-4">
                <div class="table-responsive">
                  <b-table

                      ref="table"
                      :items="shopOrderProducts"
                      :fields="getFields()"
                      responsive="sm"
                      :per-page="table.perPage"
                      :current-page="table.currentPage"
                      :sort-by.sync="table.sortBy"
                      :sort-desc.sync="table.sortDesc"

                      :filter-included-fields="table.filterOn"
                      :empty-text="$t('message.no-elements')"
                      :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                    <template v-slot:cell(id)="{ item }">
                      <div class="custom-control custom-checkbox">
                        <input :value="item.id" v-model="form.productIds" type="checkbox" class="custom-control-input" :id="`check-${item.id}`" :class="form.productIds.includes(item.id) ? 'custom-control-label-selected' : ''" />
                        <label class="custom-control-label" :for="`check-${item.id}`">
                          <div :set="productVariation = getProductVariation(item.productVariationId)">
                            <template v-if="productVariation">
                              <div :set="product = getProduct(productVariation.productId)">
                                <span v-if="product" class="font-weight-light">{{ product.name }}</span>
                              </div>
                            </template>
                          </div>
                        </label>
                      </div>
                    </template>
                  </b-table>

                  <b-form-checkbox :value="isSameProductIds" @change="selectAllProducts" class="py-4">Zaznacz/Odznacz Wszystkie</b-form-checkbox>
                </div>
              </div>

              <div class="mb-2">
                <h5 class="text-center">Podaj orientacyjne wymiary paczki dla ww. produktów</h5>

                <div class="row">
                  <div class="col-2">
                  </div>

                  <div class="col-2">
                    <label>Szerokość</label>
                    <input type="number" class="form-control" min="1" v-model="form.parcelData.width" :class="{ 'is-invalid': submitted && $v.form.parcelData.width.$error }">
                    <div v-if="submitted && !$v.form.parcelData.width.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                  </div>

                  <div class="col-2">
                    <label>Wysokość</label>
                    <input type="number" class="form-control" min="1" v-model="form.parcelData.height" :class="{ 'is-invalid': submitted && $v.form.parcelData.height.$error }">
                    <div v-if="submitted && !$v.form.parcelData.height.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                  </div>

                  <div class="col-2">
                    <label>Długość</label>
                    <input type="number" class="form-control" min="1" v-model="form.parcelData.depth" :class="{ 'is-invalid': submitted && $v.form.parcelData.depth.$error }">
                    <div v-if="submitted && !$v.form.parcelData.depth.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                  </div>

                  <div class="col-2">
                    <label>Waga</label>
                    <input type="number" class="form-control" min="1" v-model="form.parcelData.weight" :class="{ 'is-invalid': submitted && $v.form.parcelData.weight.$error }">
                    <div v-if="submitted && !$v.form.parcelData.weight.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="row mb-4">
                <div class="col-12 col-lg-2">
                  <label>Zawartość paczki:</label>
                </div>

                <div class="col-12 col-lg-3">
                  <input v-model="form.parcelData.content" class="form-control" type="text" :class="{ 'is-invalid': submitted && $v.form.parcelData.content.$error }" />
                  <div v-if="submitted && !$v.form.parcelData.content.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-12 col-lg-2">
                  <label>Ilość paczek:</label>
                </div>

                <div class="col-12 col-lg-3">
                  <input v-model="form.parcelData.parcelCount" class="form-control" type="number" :class="{ 'is-invalid': submitted && $v.form.parcelData.parcelCount.$error }"  />
                  <div v-if="submitted && !$v.form.parcelData.parcelCount.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                </div>
              </div>

              <div class="row mb-4" v-if="packageSenders">
                <div class="col-12 col-lg-2">
                  <label>Nadawca przesyłki:</label>
                </div>

                <div class="col-12 col-lg-3">
                  <select v-model="form.parcelData.packageSenderId" class="custom-select" :class="{ 'is-invalid': submitted && $v.form.parcelData.packageSenderId.$error }">
                    <option value="" disabled selected hidden>-- Wybierz nadawcę przesyłki --</option>
                    <option v-for="(item, index) in packageSenders" :key="index" :value="item.id">{{ item.displayName }}</option>
                  </select>
                  <div v-if="submitted && !$v.form.parcelData.packageSenderId.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                </div>
              </div>

              <div class="py-2">
                <b>Wypełnij pola adresowe odbiorcy</b>

                <div class="py-4">

                  <div class="row">

                  <div class="form-group col-lg-6">
                    <div class="form-group row">
                      <label class="col-sm-2">Imię:</label>
                      <div class="col-sm-10">
                        <div class="row">
                          <div class="col-md-8">
                            <input v-model="form.parcelData.firstName" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.form.parcelData.firstName.$error }">
                            <div v-if="submitted && !$v.form.parcelData.firstName.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Nazwisko:</label>
                      <div class="col-sm-10">
                        <div class="row">
                          <div class="col-md-8">
                            <input v-model="form.parcelData.lastName" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.form.parcelData.lastName.$error }">
                            <div v-if="submitted && !$v.form.parcelData.lastName.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Telefon:</label>
                      <div class="col-sm-10">
                        <div class="row">
                          <div class="col-md-8">
                            <input v-model="form.parcelData.phoneNumber" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.form.parcelData.phoneNumber.$error }">
                            <div v-if="submitted && !$v.form.parcelData.phoneNumber.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Zleć podjazd kuriera:</label>
                      <div class="col-sm-10">
                        <div class="row">
                          <div class="col-md-8">
                            <div class="custom-control custom-checkbox py-2">
                              <input id="dispatch-order" v-model="form.parcelData.dispatchOrder" type="checkbox" class="custom-control-input" />
                              <label for="dispatch-order" class="custom-control-label"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-6">

                    <div class="form-group row">
                      <label class="col-sm-2">Adres:</label>
                      <div class="col-sm-10">
                        <div class="row">
                          <div class="col-md-8">
                            <input v-model="form.parcelData.street" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.form.parcelData.street.$error }">
                            <div v-if="submitted && !$v.form.parcelData.street.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Kod pocztowy:</label>
                      <div class="col-sm-10">
                        <div class="row">
                          <div class="col-md-8">
                            <input v-model="form.parcelData.postCode" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.form.parcelData.postCode.$error }">
                            <div v-if="submitted && !$v.form.parcelData.postCode.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Miejscowość:</label>
                      <div class="col-sm-10">
                        <div class="row">
                          <div class="col-md-8">
                            <input v-model="form.parcelData.city" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.form.parcelData.city.$error }">
                            <div v-if="submitted && !$v.form.parcelData.city.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Kod państwa:</label>
                      <div class="col-sm-10">
                        <div class="row">
                          <div class="col-md-8">
                            <select v-model="form.parcelData.countryCode" class="custom-select" :class="{ 'is-invalid': submitted && $v.form.parcelData.countryCode.$error }">
                              <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.iso2.toUpperCase()}`" >{{ item.name }} - {{ item.iso2.toUpperCase() }}</option>
                            </select>
                            <div v-if="submitted && !$v.form.parcelData.countryCode.required" class="invalid-feedback">{{ $t('message.required') }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                </div>
              </div>

              <div class="text-center button-items">
                <b-button variant="success" @click="send">Prześlij</b-button>
                <a :href="`/dashboard/core/order/details?shopOrderId=${shopOrder.id}`" class="btn btn-info">Wróć do szczegółów zamówienia</a>
              </div>

          </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center py-3">
            <h5>{{ $t('message.loading') }}</h5>
            <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
          </div>
        </template>
      </div>
    </div>

  </Layout>
</template>

<style scoped>
.active-package {
  background: #18a689;
  border: #18a689;
  color: white;
}

.package-type {
  width: 100px;
  height: 100px;
  font-size: 50px;
  margin: 10px;
}
</style>